import $ from "jquery";
var db = firebase.firestore();

//Init Data


const loginForm = document.querySelector('#loginForm');
    loginForm.addEventListener('submit', (e) => {
    e.preventDefault();
    
    // auth user
    var email = loginForm['loginEmail'].value;
    var password = loginForm['loginPassword'].value;
    var showError = document.createElement('p');      

    firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        console.log(error.message);
        var errorMessage = error.message
        $(showError).addClass('error-message');
        $(showError).html(errorMessage);
        $(".error-message").replaceWith(showError);

    });
});




